button.mat-menu-item {
  line-height: 24px !important;
}
a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}
.mat-icon svg {
  height: 24px;
  width: 24px;
}
.mat-step-icon-content .mat-icon svg {
  height: 100%;
  width: 100%;
}


.mdc-list-item .mat-icon {
  vertical-align: middle;
}


.small-s {
  .mat-icon svg {
    height: 20px;
    width: 20px;
  }
}


.mat-primary > mat-icon, .mat-primary > * > mat-icon, .mat-primary > * > * > mat-icon {
  color: white !important;
}

/* You can add global styles to this file, and also import other style files */


@import "scss/primeng-fixs.scss";
@import "scss/colors";
@import "scss/mdi.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



mat-toolbar.mat-elevation-z4 {

  margin-bottom: 24px;
}

mat-icon, .mat-mdc-menu-item .mat-icon-no-color, .mat-mdc-menu-submenu-icon {
  color: #7D7D7D !important;
}


.size-xs {
  font-size: 12px;
}

.size-s {
  font-size: 13px;
}


.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
  padding: 0 32px 24px !important;
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}


.mat-menu-content a mat-icon {
  margin-top: 8px;

}


.mat-mdc-unelevated-button>.mat-icon, .mat-mdc-raised-button>.mat-icon, .mat-mdc-outlined-button>.mat-icon {

  height: 24px !important;
  width: 24px !important;
}

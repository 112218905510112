

.danger-text {
  color: #CC0000 !important;
}

.danger-background {
  background-color: #ff4444;
}

.warning-text {
  color: #FF8800 !important;
}

.warning-background {
  background-color: #ffbb33;
}


.success-text {
  color: #007E33 !important;
}

.success-background {
  background-color: #00C851;
}


.info-text {
  color: #0099CC !important;
}

.info-background {
  background-color: #33b5e5;
}

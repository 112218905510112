


.p-grid {

  .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {

    margin-top: 6px;
    margin-left: 4px;

  }

  .p-inputgroup {
    .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {

      margin-top: 0;
      margin-left: 4px;

    }

  }

  .p-float-label label {
    margin-left: 10px;
  }

}


mat-dialog-content .p-grid {
  margin-top: 8px;

  .p-grid {
    margin-top: 0;
  }
}

.p-fieldset .p-fieldset-content {
  padding: 0 !important;
}



.transparent-table {

  td, th, tr {
    background-color: transparent !important;
  }

}

p-table .p-datatable, p-paginator  {
    font-size: 13px;
}

.p-column-filter {
  font-size: 10px !important;
}


td.sort-padding {
  padding-left: 42px !important;
}

td > mat-icon {
  margin-top: 8px;
}
